import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from 'react'
import Accordion from '../components/Accordion'
import CallOut from '../components/CallOut'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import CMSModal from "../components/CMSModal.jsx"
import FactBox from '../components/FactBox'
import FlipCard from '../components/FlipCard'
import FlipCardContainer from '../components/FlipCardContainer'
import HeaderWithImage from '../components/HeaderWithImage'
import HTTTYP from '../components/HTTTYP'
import LinkButton from '../components/LinkButton'
import Question from '../components/Question'
import Quiz from '../components/Quiz'
import ShowMore from '../components/ShowMore'
import Slide from '../components/Slide'
import TextCarousel from '../components/TextCarousel'
import TextSlide from '../components/TextSlide'
import './DetailModal.scss'


export default function DetailModal ({isOpen, image, text, closeHandler}) {

  const shortcodes = { Card, CardContainer, FactBox, Accordion, Carousel,
    Slide, HeaderWithImage, ShowMore, FlipCard, TextCarousel, TextSlide,
    LinkButton, Quiz, Question, FlipCardContainer, CMSModal, HTTTYP, CallOut}

  const detailTextMarkdown = (markDown) => {
    return (
      <MDXProvider components={shortcodes}>
        <MDXRenderer>
          {markDown}
        </MDXRenderer>
      </MDXProvider>
    )
  }
  return (
    <div className='DetailModal'>
      {isOpen && <div className="overlay">
        <div className='fact-box-container'>
          <img className="lightbulb" src={image} alt="Lightbulb" />
          <div className='fact-body'>
            {detailTextMarkdown(text)}
          </div>
          <button onClick={closeHandler}>CLOSE</button>
        </div>
      </div>}
    </div>

  )
}