import React from 'react'
import './Scene1.scss'
import './Scene2.scss'
import './Scene3.scss'
import './Scene4.scss'
import './Scene5.scss'
import './Scene6.scss'

const SceneBase = ({ index, roomImage, sceneName, dataSource, modalHandler, setModalImage, setModalText}) => {
  const handleClickDetail = (detail) => {
    setModalImage(dataSource[detail].detailIcon)
    setModalText(dataSource[detail].detailText)
    modalHandler(true)
  }

  const clickableImage = (kebabPart) => {
    return (
      <button key={sceneName + kebabPart} onClick={()=> handleClickDetail(kebabPart)} >
        <img
          className={`${kebabPart} label`}
          src={dataSource[kebabPart].image}
          alt={kebabPart}
        />
      </button>
    )
  }

  return (
    <div className={sceneName} key={sceneName}>
      <img className="background" src={roomImage} alt="room" />
      { Object.keys(dataSource).map((key, index) => { return clickableImage(key) })}
    </div>
  )
}

const sceneGenerator = (modalHandler, setModalImage, setModalText, scenes) => {

  return (
    scenes.map((scene, index) => {
      return (
        <SceneBase
          index={index}
          roomImage={scene.backgroundImage}
          sceneName={scene.cssClass}
          dataSource={scene.interactiveItems}
          modalHandler={modalHandler}
          setModalImage={setModalImage}
          setModalText={setModalText}
        />
      )
    })
  )
}

export default sceneGenerator