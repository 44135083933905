import { MDXProvider } from "@mdx-js/react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React, { useEffect, useRef, useState } from 'react'
import SwiperCore, {
  Manipulation, Navigation, Pagination
} from 'swiper'
import 'swiper/css'
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react'
import Ankle from '../components/Ankle'
import sceneGenerator from '../components/clinic-tour/sceneGenerator'
import DetailModal from '../components/DetailModal'
import Footer from '../components/Footer'
import SectionHeader from '../components/SectionHeader'
import './clinic-tour.scss'

SwiperCore.use([Pagination,Navigation, Manipulation]);

function SlideComponent({handleActive, children}) {
  const swiperSlide = useSwiperSlide();
  const swiper = useSwiper();
  if (swiperSlide.isActive) {
    handleActive(swiper.activeIndex)
  }
  return (
    <>
      {children}
    </>
  );
}

export default function ClinicTour({data, image}) {

  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalImage, setModalImage] = useState(false)
  const [modalText, setModalText] = useState(false)

  const [activeSlide, setActiveSlide] = useState(0)
  const [swiperInst, setSwiperInst] = useState()
  const [showMobileItems, setShowMobileItems] = useState(false)
  const titleRef = useRef()
  const [isMobile, setIsMobile] = useState(false);

  const reportWindowSize = () => {
    setIsMobile(window.innerWidth < 1024)
  }

  useEffect(() => {
    if (isMobile){
      titleRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [activeSlide]);

  useEffect(() => {
    if ( typeof document !== "undefined") {
      window.addEventListener('resize', reportWindowSize);
      reportWindowSize()
    }
    return () => {
      window.removeEventListener('resize', reportWindowSize);
    }
  }, []);

  useEffect(() => {
    setModalImage("/assets/clinic-tour/scene1/icon-1a-patient.png")
    setModalText(data.mdx.frontmatter.initialModal)
    setIsOpen(true)
  }, [])

  const style = {
    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 66%, rgba(0,0,0,0.9) 100%), url(/assets/texture_light_blue_desktop.jpg)`,
  };

  const content = [
    data.mdx.frontmatter.welcome,
    data.mdx.frontmatter.vitals,
    data.mdx.frontmatter.clinic,
    data.mdx.frontmatter.exam,
    data.mdx.frontmatter.checkOut,
    data.mdx.frontmatter.pharmacy,
  ]

  const items = [
    'Check In',
    'Vitals and Intake',
    'Provider Visit',
    'Physical Exam',
    'Check out',
    'Pharmacy',
  ]

  const sceneData = {
    mobileOneB: {
      cssClass: "Scene1",
      backgroundImage: "/assets/clinic-tour/scene1/8-1-FINAL-REV.jpg",
      interactiveItems: {
        patient: { image: "/assets/clinic-tour/scene1/1b-patient-at-desk.png", detailIcon: "/assets/clinic-tour/scene1/icon-1b-patient-at-desk.png", detailText: data.mdx.frontmatter.OneBPatientAtDesk},
        valloon: { image: "/assets/clinic-tour/scene1/1c-word-valloon.png", detailIcon: "/assets/clinic-tour/scene1/icon-1c-word-balloon.png", detailText: data.mdx.frontmatter.OneCWordBalloon},
        clipboard: { image: "/assets/clinic-tour/scene1/1d-clipboard.png", detailIcon: "/assets/clinic-tour/scene1/icon-1d-clipboard.png", detailText: data.mdx.frontmatter.OneDClipboard},
        provider: { image: "/assets/clinic-tour/scene1/1e-provider.png", detailIcon: "/assets/clinic-tour/scene1/icon-1e-provider.png", detailText: data.mdx.frontmatter.OneEProvider},
      }

    },

    one: {
      cssClass: "Scene1",
      backgroundImage: "/assets/clinic-tour/scene1/1-background.jpg",
      interactiveItems: {
        patientA: { image: "/assets/clinic-tour/scene1/1a-patient.png", detailIcon: "/assets/clinic-tour/scene1/icon-1a-patient.png", detailText: data.mdx.frontmatter.OneAPatient},
        patient: { image: "/assets/clinic-tour/scene1/1b-patient-at-desk.png", detailIcon: "/assets/clinic-tour/scene1/icon-1b-patient-at-desk.png", detailText: data.mdx.frontmatter.OneBPatientAtDesk},
        valloon: { image: "/assets/clinic-tour/scene1/1c-word-valloon.png", detailIcon: "/assets/clinic-tour/scene1/icon-1c-word-balloon.png", detailText: data.mdx.frontmatter.OneCWordBalloon},
        clipboard: { image: "/assets/clinic-tour/scene1/1d-clipboard.png", detailIcon: "/assets/clinic-tour/scene1/icon-1d-clipboard.png", detailText: data.mdx.frontmatter.OneDClipboard},
        provider: { image: "/assets/clinic-tour/scene1/1e-provider.png", detailIcon: "/assets/clinic-tour/scene1/icon-1e-provider.png", detailText: data.mdx.frontmatter.OneEProvider},
      }

    },
    two: {
      cssClass: "Scene2",
      backgroundImage: "/assets/clinic-tour/scene2/2-background.jpg",
      interactiveItems: {
        balloon: { image: "/assets/clinic-tour/scene2/2a-word-balloon.png", detailIcon: "/assets/clinic-tour/scene2/icon-2a-word-balloon.png", detailText: data.mdx.frontmatter.TwoABalloon},
        patient: { image: "/assets/clinic-tour/scene2/2b-patient-.png", detailIcon: "/assets/clinic-tour/scene2/icon-2b-patient.png", detailText: data.mdx.frontmatter.TwoBPatient},
        blood: { image: "/assets/clinic-tour/scene2/2c-blood-pressure.png", detailIcon: "/assets/clinic-tour/scene2/icon-2c-blood-pressure.png", detailText: data.mdx.frontmatter.TwoCBlood},
        specimen: { image: "/assets/clinic-tour/scene2/2d-specimen-.png", detailIcon: "/assets/clinic-tour/scene2/icon-2d-specimen.png", detailText: data.mdx.frontmatter.TwoDSpecimen},
        provider: { image: "/assets/clinic-tour/scene2/2e-provider.png", detailIcon: "/assets/clinic-tour/scene2/icon-2e-provider.png", detailText: data.mdx.frontmatter.TwoEProvider},
      }

    },
    three: {
      cssClass: "Scene3",
      backgroundImage: "/assets/clinic-tour/scene3/3-background.jpg",
      interactiveItems: {
        provider: { image: "/assets/clinic-tour/scene3/3a-provider.png", detailIcon: "/assets/clinic-tour/scene3/icon-3a-provider.png", detailText: data.mdx.frontmatter.ThreeAProvider},
        poster: { image: "/assets/clinic-tour/scene3/3b-bc-poster.png", detailIcon: "/assets/clinic-tour/scene3/icon-3b-bc-poster.png", detailText: data.mdx.frontmatter.ThreeBPosterL},
        patient: { image: "/assets/clinic-tour/scene3/3c-patient.png", detailIcon: "/assets/clinic-tour/scene3/icon-3c-patient.png", detailText: data.mdx.frontmatter.ThreeCPatient},
        posterR: { image: "/assets/clinic-tour/scene3/3d-rs-poster.png", detailIcon: "/assets/clinic-tour/scene3/icon-3d-rs-poster.png", detailText: data.mdx.frontmatter.ThreeDPosterR},
        balloon: { image: "/assets/clinic-tour/scene3/3e-word-balloon.png", detailIcon: "/assets/clinic-tour/scene3/icon-3e-word-balloon.png", detailText: data.mdx.frontmatter.ThreeEBalloon},
        pamphlets: { image: "/assets/clinic-tour/scene3/3f-pamphlets-.png", detailIcon: "/assets/clinic-tour/scene3/icon-3f-pamphlets.png", detailText: data.mdx.frontmatter.ThreeFPamphlets},
      }

    },
    four: {
      cssClass: "Scene4",
      backgroundImage: "/assets/clinic-tour/scene4/4-background.jpg",
      interactiveItems: {
        patient: { image: "/assets/clinic-tour/scene4/4a-patience-table.png", detailIcon: "/assets/clinic-tour/scene4/icon-4a-patience-table.png", detailText: data.mdx.frontmatter.FourAPatient},
        lword: { image: "/assets/clinic-tour/scene4/4b-L-word-balloon.png", detailIcon: "/assets/clinic-tour/scene4/icon-4b-L-word-balloon.png", detailText: data.mdx.frontmatter.FourBWordL},
        provider: { image: "/assets/clinic-tour/scene4/4c-provider.png", detailIcon: "/assets/clinic-tour/scene4/icon-4c-provider.png", detailText: data.mdx.frontmatter.FourCProvider},
        rword: { image: "/assets/clinic-tour/scene4/4d-R-word-balloon.png", detailIcon: "/assets/clinic-tour/scene4/icon-4d-R-word-balloon.png", detailText: data.mdx.frontmatter.FourDWordR},
      }

    },
    five: {
      cssClass: "Scene5",
      backgroundImage: "/assets/clinic-tour/scene5/5-background.jpg",
      interactiveItems: {
        provider: { image: "/assets/clinic-tour/scene5/5a-provider.png", detailIcon: "/assets/clinic-tour/scene5/icon-5a-provider.png", detailText: data.mdx.frontmatter.FiveAProvider},
        phone: { image: "/assets/clinic-tour/scene5/5b-phone.png", detailIcon: "/assets/clinic-tour/scene5/icon-5b-phone.png", detailText: data.mdx.frontmatter.FiveBPhone},
        lballoon: { image: "/assets/clinic-tour/scene5/5c-L-wordwolloon.png", detailIcon: "/assets/clinic-tour/scene5/icon-5c-L-word-balloon.png", detailText: data.mdx.frontmatter.FiveCBalloonL},
        patient: { image: "/assets/clinic-tour/scene5/5d-patient.png", detailIcon: "/assets/clinic-tour/scene5/icon-5d-patient.png", detailText: data.mdx.frontmatter.FiveDPatient},
        rballoon: { image: "/assets/clinic-tour/scene5/5e-R-word-balloon.png", detailIcon: "/assets/clinic-tour/scene5/icon-5e-R-word-balloon.png", detailText: data.mdx.frontmatter.FiveEWordR},
        exit: { image: "/assets/clinic-tour/scene5/5f-exit-sign.png", detailIcon: "/assets/clinic-tour/scene5/icon-5f-exit-sign.png", detailText: data.mdx.frontmatter.FiveFExit},
      }

    },
    six: {
      cssClass: "Scene6",
      backgroundImage: "/assets/clinic-tour/scene6/6-background.jpg",
      interactiveItems: {
        drop: { image: "/assets/clinic-tour/scene6/6a-drop-off-sign.png", detailIcon: "/assets/clinic-tour/scene6/icon-6a-drop-off-sign.png", detailText: data.mdx.frontmatter.SixADropOff},
        pick: { image: "/assets/clinic-tour/scene6/6b-pick-up-sign.png", detailIcon: "/assets/clinic-tour/scene6/icon-6b-pick-up-sign.png", detailText: data.mdx.frontmatter.SixBPickUp},
        patient: { image: "/assets/clinic-tour/scene6/6c-patient-.png", detailIcon: "/assets/clinic-tour/scene6/icon-6c-patient.png", detailText: data.mdx.frontmatter.SixCPatient},
        register: { image: "/assets/clinic-tour/scene6/6d-register.png", detailIcon: "/assets/clinic-tour/scene6/icon-6d-register.png", detailText: data.mdx.frontmatter.SixDRegister},
        pharma: { image: "/assets/clinic-tour/scene6/6e-pharmacist.png", detailIcon: "/assets/clinic-tour/scene6/icon-6e-pharmacist.png", detailText: data.mdx.frontmatter.SixEPharmacist},
      }

    },

  }

  const getContent = () => {
    if (activeSlide === 0) {
      return isMobile? data.mdx.frontmatter.welcomeMobile : data.mdx.frontmatter.welcome;
    } else {
      return content[activeSlide]
    }

  }
  const mobileData = [sceneData.mobileOneB, sceneData.two, sceneData.three, sceneData.four, sceneData.five, sceneData.six]
  const desktopData = [sceneData.one, sceneData.two, sceneData.three, sceneData.four, sceneData.five, sceneData.six]
  const sceneDataSlides = isMobile ? mobileData : desktopData;
  const scenes = sceneGenerator(setIsOpen, setModalImage, setModalText, sceneDataSlides)

  const slides = [
    scenes.map((scene, index) => {
      return (
        <SwiperSlide key={index}>
          <SlideComponent
             handleActive={setActiveSlide}>
             {scene}
          </SlideComponent>
        </SwiperSlide>
      )
    })
  ]

  const menuClick = (index) => {
    setShowMobileItems(false)
    swiperInst.slideTo(index)
  }

  const menuItems = items.map((item, index) => {
    const style = index === activeSlide ? 'menu-item selected' : 'menu-item';
    return <button className={style} key={index} onClick={() => menuClick(index)}> {item} </button>
  })

  const moreItemsButtonClass = showMobileItems ? 'mobile-moreitems open' : 'mobile-moreitems';

  const centerSlide = (isMobile && activeSlide === 4) ? false : true

  const clickableImages = (activeSlide) => {
    const interativeItemList = Object.values(sceneDataSlides[activeSlide].interactiveItems)
    return (
      interativeItemList.map((item, index) => {
        return <img key={index} src={item.detailIcon}></img>
      }
    ))
  }

  return (
    <div className="ClinicTour">
      <DetailModal
        isOpen={modalIsOpen}
        closeHandler={()=> setIsOpen(false)}
        text={modalText}
        image={modalImage}/>
      <SectionHeader >
      </SectionHeader>
      <div className='desktop-width-guard'>
        <div className="header short" style={style}>
          <div className="headers">
            <h1>Clinic Experience</h1>
          </div>
        </div>
        <div className='top'>
          <div className='left' >
            <div ref={titleRef} className='item-title'>
              <h2 > {items[activeSlide]} </h2>
              <div
                className={moreItemsButtonClass}
                role="button"
                tabIndex="0"
                onClick={()=>setShowMobileItems(!showMobileItems)}
                onKeyPress={()=>setShowMobileItems(!showMobileItems)}
              >
                {`MORE ROOMS`}
              </div>
            </div>
            <div className="desktop-swiper">
              <Swiper
                slidesPerView={isMobile? 0.6 : 1}
                centeredSlides={centerSlide}
                pagination={{
                  "clickable": true}}
                onSwiper={(swiper) => setSwiperInst(swiper)}>
                  {slides}
              </Swiper>
            </div>
          </div>
          {showMobileItems && <div className="bc-menu"> {menuItems} </div>}
          <div className="bc-menu desktop"> {menuItems} </div>
        </div>
        <div className='desktop-styling'>
          <div className='content'>
            <MDXProvider >
              <MDXRenderer>
                {getContent()}
              </MDXRenderer>
            </MDXProvider>
            <div className="clickable-images">
              <div className="title">Explore the clinic</div>
              <div className="directions"> Find and tap on these objects above to learn more! </div>
              <div className="icons">
                {clickableImages(activeSlide)}
              </div>
            </div>
          </div>
          <Ankle showClinicTour={false}>
          </Ankle>
        </div>
      </div>
      <Footer>
      </Footer>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    mdx(slug: {eq: "clinic-tour/clinic-tour"}) {
      body
      frontmatter {
        welcome
        welcomeMobile
        checkIn
        vitals
        clinic
        exam
        checkOut
        pharmacy
        initialModal
        OneAPatient
        OneBPatientAtDesk
        OneCWordBalloon
        OneDClipboard
        OneEProvider
        TwoABalloon
        TwoBPatient
        TwoCBlood
        TwoDSpecimen
        TwoEProvider
        TwoFWindow
        ThreeAProvider
        ThreeBPosterL
        ThreeCPatient
        ThreeDPosterR
        ThreeEBalloon
        ThreeFPamphlets
        FourAPatient
        FourBWordL
        FourCProvider
        FourDWordR
        FiveAProvider
        FiveBPhone
        FiveCBalloonL
        FiveDPatient
        FiveEWordR
        FiveFExit
        SixADropOff
        SixBPickUp
        SixCPatient
        SixDRegister
        SixEPharmacist
      }
    }
  }
`


